.container {
    display: flex;
    justify-content: center;
    align-items: top;
    margin-top: 50px;
    height: 100vh;
    position: flex;
}

.plate-link-icon {
    margin-left: 5px;
    color: #888888;
    font-size: 15px;
}

.plate-link-icon:hover {
    color: #323232;
}

.contact-us-container {
    position: fixed;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    justify-content: center;
    align-items: center;
    padding: 30px;
    overflow-y: scroll;
    overflow: scroll;
}

.header-bar {
    background-color: white;
    padding: 10px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 10px;
    box-shadow: 0 0.1px 30px rgba(0, 0, 0, 0.5);
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
}

.header-links {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header-links a {
    text-decoration: none;
}

.header-links a:hover {
    text-decoration: none;
}

.input-box {
    width: 100%;
    height: 25px;
    border: 1px solid #ccc;
    border-radius: 3px;
    padding: 5px;
}

.alert-values {
    color: red;
    margin-left: 10px;
    margin-top: 5px;
    font-size: 10px;
}

a {
    text-decoration: none;
    color: inherit;
}

td {
    text-align: center;
}

th {
    padding-left: 10px;
    padding-right: 10px
}

.link-button {
    display: inline-block;
    background-color: transparent;
    color: #323232;
    border-radius: 8px;
    padding: 8px;
    width: auto;
    margin-left: 50px;
    transition: all 0.1s ease-in-out;
}

.link-button.active {
    box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.5);
    background-color: #323232;
    color: #FFFFFF;
}

.link-button:hover {
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.link-button-sidebar {
    display: inline-block;
    background-color: transparent;
    color: #323232;
    border-radius: 8px;
    padding: 8px;
    width: 175px;
    margin-right: 0px;
    cursor: pointer;
}

.link-button-sidebar.active {
    background-color: #323232;
    color: #FFFFFF;
}

.link-button-sidebar:hover {
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.sidebar-container {
    display: flex;
    flex-direction: column;
    justify-content: top;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    padding: 10px;
    width: 200px;
    background-color: #F1F1F1;
    z-index: 2;
}

input {
    margin-top: 1rem;
}

input::file-selector-button {
    font-weight: bold;
    color: #323232;
    padding: 0.5em;
    border: none;
    margin: 4px;
    border-radius: 3px;
    background-color: white;
    cursor: pointer;
    width: 100%;
    height: 35px;
    border-radius: 8.5px;
    margin-left: 10px;
    font-size: 15px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
}

.dropdown {
    border-radius: 8.5px;
    height: 35px;
    width: 100%;
    margin-left: 10px;
    font-size: 20px;
    margin-right: 10px;
    margin-top: 18px;
    text-align: "left";
    border: "transparent";
    box-shadow: "0px 2px 4px rgba(0, 0, 0, 0.25)";
    background-color: "#FFFFFF";
    cursor: "pointer";
    appearance: "none";
}

.dropdown {
    border-radius: 8.5px;
    height: 35px;
    width: 100%;
    min-width: 200px;
    margin-left: 10px;
    font-size: 15px;
    font-weight: bold;
    margin-right: 10px;
    margin-top: 18px;
    text-align: left;
    border: transparent;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.25);
    background-color: #FFFFFF;
    cursor: pointer;
    appearance: none;
}

@media (max-width: 1000px) {
    .dropdown {
        font-size: 10px;
    }
}