.stats-container {
	display: flex;
    flex-direction: column;
	justify-content: center;
	margin-top: 20px;
	margin-left: 215px;
	width: calc(100vw - 215px);
}

.stat-row {
	display: flex;
	flex-direction: row;
	justify-content: center;
	flex-wrap: wrap;
	gap: 15px;
	align-items: center;
	width: 95%;
	margin-bottom: 20px;
}

.stat-card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	min-width: 300px;
	width: 100%;
	max-width: 32%;
	height: 200px;
	background-color: #fff;
	border-radius: 10px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.stat-card-title {
	font-weight: 700;
	margin-bottom: 10px;
	text-align: center;
}

.stat-card-stat {
	font-size: 1.5rem;
	font-weight: 400;
	margin-top: 0px;
}