.settings-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: calc(100vw - 210px);
	margin-left: 215px;
}

.directory-title {
	display: flex;
	font-size: 50px;
	font-weight: bold;
	margin-bottom: 20px;
}

.account-name {
	color: #323232;
	text-decoration: underline;
	cursor: pointer;
}

.search-bar {
	display: flex;
	width: 60%;
	height: 50px;
	border-radius: 6px;
	border: #dbdbdb 2px solid;
	padding-top: 3px;
	padding-bottom: 3px;
	padding-left: 5px;
	font-size: 25px;
}

.search-bar:focus {
	outline: none;
	border: #FFE374 2px solid;
	box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
}

.result-container {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	gap: 20px;
	justify-content: center;
	align-items: center;
	width: 90%;
	margin-top: 50px;
}

.result-item-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 45%;
	box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
	border-radius: 15px;
	height: 120px;
}

.result-item-container:hover {
	box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.2);
	background-color: #F1F1F1;
}

.result-item {
	display: flex;
	font-size: 30px;
	font-weight: bold;
	color: #323232;
}

.editor-container {
	display: flex;
	flex-direction: column;
	height: fit-content;
	margin: auto;
	overflow: auto;
	transition: opacity 0.5s ease-in-out;
	margin-top: 50px;
	margin-left: 220px;
	width: calc(100% - 260px);
    height: auto;
	border-radius: 48px;
	padding: 20px;
	z-index: 9999;
}

.button-tester {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 300px;
    height: 100px;
    background-color: #FFFFFF;
    border-radius: 7px;
    box-shadow: 0px .88px 4.38px 0.88px rgba(0, 0, 0, .25);
    margin: 0 auto;
    margin-top: 50px;
}

.editor-title {
	display: flex;
	font-size: 30px;
	font-weight: bold;
	color: #323232;
	text-align: center;
	margin: 0 auto;
	margin-bottom: 20px;
}

.editor-label {
	font-size: 16px;
	font-weight: bold;
	color: #323232;
	margin-bottom: 10px;
	margin-left: 5px;
}

.editor-input {
	padding: 5px;
	font-size: 14px;
	font-weight: 500;
	color: black;
	border: 2px solid #323232;
	border-radius: 5px;
	background-color: white;
	box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
	color: #323232;
	margin: 5px;
	margin-bottom: 10px;
	width: 95%;
	height: 20px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.editor-input:focus {
	outline: none;
	border: 2px solid #FFE374;
	box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
}

::-webkit-datetime-edit-ampm-field:focus,
::-webkit-datetime-edit-day-field:focus,
::-webkit-datetime-edit-hour-field:focus,
::-webkit-datetime-edit-millisecond-field:focus,
::-webkit-datetime-edit-minute-field:focus,
::-webkit-datetime-edit-month-field:focus,
::-webkit-datetime-edit-second-field:focus,
::-webkit-datetime-edit-week-field:focus,
::-webkit-datetime-edit-year-field:focus,
::-webkit-datetime-edit-text:focus {
	background-color: #D9D9D9;
	color: black;
	outline: none;
}

.editor-input-dropdown {
	padding: 5px;
	font-size: 14px;
	font-weight: 500;
	color: black;
	border: 2px solid #323232;
	border-radius: 5px;
	background-color: white;
	box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
	color: #323232;
	margin: 5px;
	margin-bottom: 10px;
	width: 97%;
	height: 40px;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.editor-input-dropdown:focus {
	outline: none;
	border: 2px solid #FFE374;
	box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
}

.editor-button-container {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	gap: 20px;
}

.editor-cancel-button {
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	margin-bottom: -20px;
	width: 150px;
	height: 35px;
	border-radius: 12px;
	background-color: white;
	font-size: 20px;
	font-weight: bold;
	color: #323232;
	cursor: pointer;
	border: transparent;
	box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.12);
}

.editor-cancel-button:hover {
	box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.2);
}

.editor-delete-button {
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	margin-bottom: -20px;
	width: 150px;
	height: 35px;
	border-radius: 12px;
	background-color: rgb(239, 194, 194);
	font-size: 20px;
	font-weight: bold;
	color: #323232;
	cursor: pointer;
	border: transparent;
	box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.12);
}

.editor-save-button {
	display: block;
	margin-left: auto;
	margin-right: auto;
	margin-top: 20px;
	margin-bottom: -20px;
	width: 150px;
	height: 35px;
	border-radius: 12px;
	background-color: #ffe374;
	font-size: 20px;
	font-weight: bold;
	color: #323232;
	cursor: pointer;
	border: transparent;
	box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.12);
}

.editor-save-button:hover {
	box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.2);
}

.editor-delete-button:hover {
	box-shadow: 0px 0px 18px 4px rgba(0, 0, 0, 0.2);
}

.error-message {
	color: red;
	font-size: 14px;
	font-weight: bold;
	margin-top: 10px;
	margin-left: 5px;
}

/* CSS */
:root {
	--arrow-bg: #949494;
	--arrow-icon: url(https://upload.wikimedia.org/wikipedia/commons/9/9d/Caret_down_font_awesome_whitevariation.svg);
	--option-bg: #323232;
	--select-bg: rgba(255, 255, 255, 0.2);
}

select {
	/* Reset */
	appearance: none;
	border: 0;
	outline: 0;
	font: inherit;
	font-size: 12px;
	/* Personalize */
	width: 200px;
	height: 40px;
	padding: 0rem 0rem 0rem 1rem;
	background: var(--arrow-icon) no-repeat right 0.8em center / 1.4em,
		linear-gradient(to left, var(--arrow-bg) 3em, var(--select-bg) 3em);
	color: #323232;
	border-radius: 1em;
	box-shadow: 0 0 1em 0 rgba(0, 0, 0, 0.2);
	cursor: pointer;
	margin-top: 20px;
	margin-right: 20px;

	/* Remove IE arrow */
	&::-ms-expand {
		display: none;
	}

	/* Remove focus outline */
	&:focus {
		outline: none;
	}

	/* <option> colors */
	option {
		color: #323232;
		background-color: var(--option-bg);
	}
}