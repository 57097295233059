body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
        'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
        sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
    .font-stretched {
        font-stretch: semi-expanded;
    }

    .up-lg {
        box-shadow: 0 -15px 30px -10px rgba(0, 0, 0, 0.5)
    }

    .down-lg {
        box-shadow: 0 15px 30px -15px rgba(0, 0, 0, 0.5)
    }

    .fcenter {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .fr {
        display: flex;
        flex-direction: row;
    }

    .fc {
        display: flex;
        flex-direction: column;
    }

    .fcc {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
    }

    .frc {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
    }

    .no-scrollbar::-webkit-scrollbar {
        display: none;
    }

    .no-scrollbar {
        -ms-overflow-style: none;
        scrollbar-width: none;
    }

    .text-glow {
        text-shadow: '0 0 5px #FFFFFF, 0 0 10px #FFFFFF, 0 0 20px #FFFFFF, 0 0 30px #FFE374';
    }

    .main {}
}